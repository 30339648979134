<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.insurance').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col>
            <text-field
              v-model="insuranceAmount"
              :title="$t('courierSet.insuranceAmount')"
              rules="double:2"
              @input="update"
            />
          </v-col>
          <v-col>
            <select-field
              v-model="insuranceCurrency"
              :title="$t('courierSet.defaultCurrency')"
              :filed-items="currencyItems"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextField from '@/components/inputs/TextField.vue';
import SelectField from '@/components/inputs/SelectField.vue';
import DodajPaczke from '@/interfaces/Speditors/DodajPaczke/DodajPaczke';
import { currencyItems } from '@/constants/CurrencyItems';

export default {
  components: {
    TextField,
    SelectField,
  },
  props: {
    confData: { type: DodajPaczke, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: null },
  },

  data: () => ({
    confSet: null,
    id: null,
    additionalPayments: null,
    tenantId: null,
    insuranceAmount: null,
    insuranceCurrency: null,
    codAmount: null,
    codCurrency: null,
    currencyItems,
  }),
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.additionalPayments = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          id: this.insuranceId,
          tenantId: this.tenantId,
          codAmount: this.codAmount,
          codCurrency: this.codCurrency,
          insuranceAmount: this.insuranceAmount,
          insuranceCurrency: this.insuranceCurrency,
        },
      };
      this.$emit('input', this.additionalPayments);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.details = this.confSet?.dodajPaczkeDefaultValues?.details;
      this.id = this.details?.id ? this.details?.id : null;
      this.tenantId = this.details?.tenantId ? this.details?.tenantId : null;
      this.insuranceAmount = this.details?.insuranceAmount ? this.details?.insuranceAmount : null;
      this.insuranceCurrency = this.details?.insuranceCurrency
        ? this.details?.insuranceCurrency
        : null;
      this.codAmount = this.details?.codAmount ? this.details?.codAmount : null;
      this.codCurrency = this.details?.codCurrency ? this.details?.codCurrency : null;
    },
  },
};
</script>

<style scoped lang="scss">
.v-select {
  padding: 0px 10px 0px 10px;
}
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
