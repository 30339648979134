export default class Details {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.id = data ? data.id : '';
    this.tenantId = data ? data.tenantId : '';
    this.insuranceAmount = data ? data.insuranceAmount : '';
    this.insuranceCurrency = data ? data.insuranceCurrency : '';
    this.codAmount = data ? data.codAmount : '';
    this.codCurrency = data ? data.codCurrency : '';
  }
}
