import DodajPaczkeDefaultValues from './DodajPaczkeDefaultValues';

export default class DodajPaczke {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.id = data ? data.id : '';
    this.tenantId = data ? data.tenantId : '';

    this.clientId = data ? data.clientId : '';

    this.configurationName = data ? data.configurationName : '';

    this.password = data ? data.password : '';
    this.metadata = data ? data.metadata : [];

    this.courier = data ? data.courier : '';
    this.login = data ? data.login : '';

    this.shipperId = data ? data.shipperId : '';

    this.provider = data ? data.provider : '';
    this.urlAddress = data ? data.urlAddress : '';
    this.dodajPaczkeDefaultValues = new DodajPaczkeDefaultValues();
  }
}
