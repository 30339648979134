<template>
  <v-container class="pt-0">
    <v-card elevation="0">
      <v-card-title class="pb-0">
        {{ $t('courierSet.setData').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="configurationName"
              :title="$t('courierSet.configurationName')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="id"
              :title="$t('courierSet.configurationId')"
              rules="required"
              :disabled="true"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <select-field
              v-model="courierCode"
              :title="$t('courierSet.deliverySpeditor')"
              rules="required"
              :filed-items="SHIPMENT_METHOD_CODES"
              disabled
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-card
      elevation="0"
      class="mt-5"
    >
      <v-card-title class="pb-0">
        {{ $t('courierSet.authData').toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="login"
              :title="$t('courierSet.login')"
              rules="required"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="password"
              :title="$t('courierSet.password')"
              type="password"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <text-field
              v-model="urlAddress"
              :title="$t('courierSet.urlAddress')"
              :rules="'required|url'"
              @input="update"
            />
          </v-col>
          <v-col class="pt-0">
            <text-field
              v-model="shipperId"
              :title="$t('courierSet.shipperId')"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <text-field
              v-model="provider"
              :title="$t('courierSet.provider')"
              rules="required"
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import TextField from '@/components/inputs/TextField.vue';
import { SHIPMENT_METHOD_CODES } from '@/constants/ShipmentMethodCodes.js';

export default {
  components: { TextField },
  props: {
    confData: { type: Object, default: null },
    value: { type: Object, default: null },
    isNew: { type: Boolean, default: false },
  },
  data() {
    return {
      SHIPMENT_METHOD_CODES,
      courierCode: '',
      confSet: null,
      id: null,
      tenantId: null,
      clientId: null,
      login: null,
      password: null,
      shipperId: null,
      urlAddress: null,
      provider: null,
      configurationName: null,
      serviceAuthorization: null,
    };
  },
  async created() {
    this.getConfSet();
  },
  methods: {
    update() {
      this.serviceAuthorization = {
        parameters: {
          clientEntityState: this.isNew ? 4 : 3,
          configurationName: this.configurationName,
          id: this.id,
          tenantId: this.tenantId,
          login: this.login,
          password: this.password,
          urlAddress: this.urlAddress,
          shipperId: this.shipperId,
          provider: this.provider,
        },
      };
      this.$emit('input', this.serviceAuthorization);
    },
    getConfSet() {
      this.confSet = this.confData;
      this.id = this.confSet?.id ? this.confSet.id : uuidv4();
      this.tenantId = this.confSet !== null ? this.confSet.tenantId : null;
      this.clientId = this.confSet !== null ? this.confSet.clientId : null;
      this.login = this.confSet !== null ? this.confSet.login : null;
      this.password = this.confSet !== null ? this.confSet.password : null;
      this.shipperId = this.confSet !== null ? this.confSet.shipperId : null;
      this.provider = this.confSet !== null ? this.confSet.provider : null;
      this.courierCode = this.confSet !== null ? this.confSet.provider : 'dodajPaczke';
      this.urlAddress = this.confSet !== null ? this.confSet.urlAddress : null;
      this.configurationName = this.confSet !== null ? this.confSet.configurationName : null;
      this.userName = this.confSet !== null ? this.confSet.clientId : null;
    },
  },
};
</script>

<style scoped lang="scss">
.col {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
