export default class Item {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.id = data ? data.id : '';
    this.tenantId = data ? data.tenantId : '';

    this.weightInKg = data ? data.weightInKg : '';
    this.lengthInCm = data ? data.lengthInCm : '';
    this.widthInCm = data ? data.widthInCm : '';
    this.heightInCm = data ? data.heightInCm : '';

    this.packageType = data ? data.packageType : '';
  }
}
