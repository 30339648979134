// import Receiver from './Receiver';
import Item from './Item';
import Details from './Details';

export default class DodajPaczkeDefaultValues {
  constructor(data) {
    this.clientEntityState = data ? data.clientEntityState : 4;
    this.tenantId = data ? data.tenantId : '';
    this.id = data ? data.id : '';
    this.description = data ? data.description : '';
    this.deliveryRemarks = data ? data.deliveryRemarks : '';
    this.customerReference = data ? data.customerReference : '';
    // this.receiver = new Receiver();
    this.item = new Item();
    this.details = new Details();
  }
}
